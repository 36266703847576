/* You can add global styles to this file, and also import other style files */

.app-vh-50 {
  min-height: 50vh;
}

.app-vh-70 {
  min-height: 70vh;
}




/* Estilos generales del "loading" */
.loading-init {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  perspective: 1000px;  /* Añadir perspectiva */
}

/* Estilos del spinner o animación de carga */
.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f1c40f; /* Asegúrate de que el color de borde superior sea amarillo */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: translate3d(0, 0, 0); /* Forzar nueva capa de composición */
  animation: spin 2s linear infinite;
}

/* Definición de la animación */
@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top-color: #f1c40f; /* Amarillo */
  }
  33% {
    border-top-color: #3498db; /* Azul */
  }
  66% {
    border-top-color: #ff5733; /* Rojo */
  }
  100% {
    transform: rotate(360deg);
    border-top-color: #f1c40f; /* Amarillo */
  }
}




/* Estilos para los input */
.custom-input {
  text-align: center;
  /* Estilos adicionales para los input si es necesario */
}

/* Estilos para los placeholders de los input */
.custom-input::placeholder {
  text-align: center;
  /* Estilos adicionales para los placeholders si es necesario */
}





/* Estilos para el footer */
.footer-fixed {
  position: absolute;
  bottom: 0;
  width: 100%;
}
